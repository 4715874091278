// shared theme local variables
@import 'variables';
@import 'font-face/roboto';

/* Install axl theme	*/
@import "@atc/axl-atc/scss/axl-atc";

/* FYC-only components */
@import "components/alpha_showcase";
@import "components/filters";
@import "components/media_gallery_image";
@import "components/media_gallery_modal";
@import "components/page_overlay";
@import "components/performance-accessibility";
@import "components/sticky_header";
@import "components/srp_alert";

/* THIS IS ICO ATC BRAND SPECIFIC */
@import "atcui/radio_toggle";


.fade-in {
  animation: fade-in 2s;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

[data-cmp="vdp-paid-landing-container"] .alert-info::before {
  display: none;
}

[data-cmp="preorderCard"] div.image-container.bg-gray-darker {
  background-color: #fff !important;

  img {
    padding: 0 16px;
  }
}

.progress-bar-consumer-rating .progress-bar-warning {
  background-color: #e8bb38;
}

#model-reference-container:target {
  scroll-margin-top: 24px;
}